import { Space, Tag } from "antd";
import "./style.scss";
import { useNavigate } from "react-router-dom";

export default function CardBlog({ data }) {
  
  const navigate = useNavigate();

  return (
    <div id="id_card_blog_body">
      <img src={data.img_miniatura} alt="immPrin" />
      <Space size={[0, 8]} wrap>
        <Tag
          onClick={() => navigate(`/blog/${data.name_category}`)}
          color="#ff801e"
          style={{ fontSize: "10px" }}
        >
          {data.name_category}
        </Tag>
        {/* <Tag color="#ff7b95" style={{fontSize:'10px'}}>FISICA</Tag> */}
      </Space>
      <h2>{data.title}</h2>
      <h6>
        Publicado {data.fecha} | {data.author}
      </h6>
      <div style={{height:"auto"}}>
        <p >{data.description[0]}</p>
      </div>
    </div>
  );
}

import { Space, Tag } from "antd";
import './style.scss'

export default function CardPrincipal() {
  return (
    <div id="id_card_blog">
      <img
        src="https://www.hostinger.es/tutoriales/wp-content/uploads/sites/7/2019/02/what-is-a-blog.png"
        alt="immPrin"
      />
      <Space size={[0, 8]} wrap>
        <Tag color="#ff801e">SALUD</Tag>
        <Tag color="#ff7b95">FISICA</Tag>
      </Space>
      <h2>Claves para mantener un estilo de vida saludable</h2>
      <h6>Publicado 16 de mayo del 2023 | Dr. Alejandro González</h6>
      <p>
        Te daremos consejos prácticos para promover un estilo de vida saludable.
        Desde la importancia de una dieta balanceada y la actividad física
        regular, hasta la gestión del estrés y el cuidado de la salud mental.
      </p>
    </div>
  );
}

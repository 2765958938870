const data = [
    "1. ¿Qué es Doctor App? \n es Doctor App",
    "2. ¿Cómo puedo acceder a los servicios de Doctor App? \n es Doctor App 2",
    "3. ¿Qué tipos de servicios de salud ofrece Doctor App? \n es Doctor App 3",
    "4. ¿Cómo funciona la inteligencia artificial en Doctor App? \n es Doctor App 4",
    "5. ¿Cómo puedo contactar a un médico o profesional de la salud? \n es Doctor App 5",
    "6. ¿Qué beneficios obtiene mi empresa al utilizar Doctor App? \n es Doctor App 6",
    "7. ¿La app es gratuita? \n es Doctor App 7",
    "8. ¿Puedo afiliar a mi familia a Doctor App? \n es Doctor App 8",
    "9. ¿Cuál es la disponibilidad de los servicios de Doctor App? \n es Doctor App 9",
    "10. ¿Cómo puedo obtener más información sobre Doctor App? \n es Doctor App 9",
];
export default data;
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ConfigProvider } from "antd";
import "react-responsive-carousel/lib/styles/carousel.min.css";


// import './index.scss'
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: "#b01319",
      },
      components: {
        Button: {
          colorText: "#b01319",
          colorPrimaryHover:'white',
        },
      },
    }}
  >
    <App />
  </ConfigProvider>
  // </React.StrictMode>
);

import React, { useEffect, useState } from "react";
import CollapsePanel from "antd/es/collapse/CollapsePanel";
import "./style.scss";
import { Button, Collapse } from "antd";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import CardService from "../../components/CardService";
import img1 from "../../assets/1-1.png";
import img2 from "../../assets/1-2.png";
import img3 from "../../assets/1-3.png";
import img4 from "../../assets/1-4.png";
import iconMano from "../../assets/icons/icon-manos.svg";
import dr from "../../assets/dr.png";
import iconManos from "../../assets/icons/manos.svg";
import iconEscalera from "../../assets/icons/escaleras.svg";
import iconMaleta from "../../assets/icons/maleta.svg";
import iconSilla from "../../assets/icons/silla.svg";
import Suscribete from "../../components/Suscribete";
import Footer from "../../components/footer";
import Menu from "../../components/Menu";
import ButtonSuscribete from "../../components/Button/ButtonSuscribete";
import logo from "../../assets/LOGO-RECTANGULAR-DRAPP-V2.png";
import { useNavigate } from "react-router-dom";
import CustomModal from "../../components/Modal/CustomModal";
import imgCard1 from "../../assets/about/card_1.png";
import imgCard2 from "../../assets/about/card_2.png";
import imgCard3 from "../../assets/about/card_3.png";
import imgCard4 from "../../assets/about/card_4.png";
import imgCard1Short from "../../assets/medicina-familiar.png";
import imgCard2Short from "../../assets/psicologia.png";
import imgCard3Short from "../../assets/nutricion.png";
import imgCard4Short from "../../assets/finanzas-personales.png";


export default function Home() {
  const [openPanels, setOpenPanels] = React.useState("1");

  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [isModalOpen4, setIsModalOpen4] = useState(false);

  const isMobile =
    /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(
      navigator.userAgent
    );
  
  const showModal = () => {
    setIsModalOpen(true);
  };
  const showModal2 = () => {
    setIsModalOpen2(true);
  };
  const showModal3 = () => {
    setIsModalOpen3(true);
  };
  const showModal4 = () => {
    setIsModalOpen4(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };
  const handleCancel3 = () => {
    setIsModalOpen3(false);
  };
  const handleCancel4 = () => {
    setIsModalOpen4(false);
  };

  gsap.registerPlugin(ScrollTrigger);

  useEffect(()=> {
    
    gsap.from(".cls_container_title_page",{
      scrollTrigger:{
        trigger: ".cls_container_section_one",
        toggleActions: "play reverse restart reverse",
        start:'top center',
      },
      opacity: 0, 
      x: -50,
      duration: 1.7,
    });

    gsap.from(".cls_sub_title",{
      scrollTrigger:{
        trigger: ".cls_container_section_two",
        toggleActions: "play reverse restart reverse",
        start:'top center',
      },
      opacity: 0, 
      y: 100,
      duration: .5,
    });

    gsap.from(".cls_container_subTitle_text",{
      scrollTrigger:{
        trigger: ".cls_container_subTitle",
        toggleActions: "play reverse restart reverse",
        start:'top center',
      },
      y: 100,
      duration: .5,
      opacity:0,
    });

    gsap.from(".cls_container_que_nos_hace_diferent_img",{
      scrollTrigger:{
        trigger: ".cls_container_que_nos_hace_diferent",
        toggleActions: "play reverse restart reverse",
        start:'top center',
      },
      x: -100,
      duration: .5,
      opacity:0,
    });

    gsap.from(".cls_container_beneficios_title",{
      scrollTrigger:{
        trigger: ".cls_container_beneficios",
        toggleActions: "play reverse restart reverse",
        start:'top center',
      },
      y: 100,
      duration: .5,
      opacity:0,
    });

    gsap.from(".cls_suscribete_title_effect",{
      scrollTrigger:{
        trigger: ".cls_suscribete_title_effect",
        toggleActions: "play none none none",
        start:'center center',
      },
      y: 100,
      duration: .5,
      opacity:0,
    });

    gsap.from(".cls_suscribete_imagen_effect",{
      scrollTrigger:{
        trigger: ".cls_container_beneficios_title",
        toggleActions: "play none restart reverse",
        start:'center center',
      },
      x: 100,
      duration: 2,
      opacity:0,
    });

  });

  const videoUrl = isMobile ? "https://resource.doctorapp.pe/movildrapp.mp4" : "https://resource.doctorapp.pe/videoapp.mp4";

  return (
    <div id="id_container_home">
      <div className="cls_container_one_video">
        <div className="cls_container_section_one">
          <div className="cls_header">
            <div className="cls_container_logo">
              <img
                onClick={() => navigate(`/`)}
                src={logo}
                className="cls_logo_one"
                alt="logo"
              />
            </div>
            <div className="cls_menu_header">
              <div className="cls_option_menu">
                <ButtonSuscribete />
              </div>
              <Menu />
            </div>
          </div>
          <div className="cls_container_title cls_container_title_page">
            <h1>
              ASEGURA EL BIENESTAR DE<br />
              <span>tus colaboradores</span>
            </h1>
            { isMobile ? "" : <h4>
              Únete a la app líder en Perú y Latinoamérica, donde la
              inteligencia artificial se combina con la mejor experiencia
              profesional.
            </h4>}
            <Button className="animate_button_scale">Suscríbete gratis</Button>
          </div>
        </div>
        <div>
          <video autoPlay loop muted className="video">
            <source src={videoUrl} type="video/mp4" />
          </video>
        </div>
      </div>
      <div className="cls_container_section_two">
        <h2 className="cls_sub_title">
          OPTIMIZAMOS LOS SERVICIOS INTEGRALES DE SALUD Y BIENESTAR{" "}
          <span>DE TUS COLABORADORES</span>
        </h2>
        <div className="cls_container_service">
          <div className="cls_group_card">
            <CardService
              eventClick={showModal}
              img={img1}
              title={"Medicina Familiar"}
              isMobile={isMobile}
            />
            <CardService
              eventClick={showModal2}
              img={img2}
              title={"Psicología"}
              isMobile={isMobile}
            />
            <CardService
              eventClick={showModal3}
              img={img3}
              title={"Nutrición"}
              isMobile={isMobile}
            />
            <CardService
              eventClick={showModal4}
              img={img4}
              title={"Finanzas Personales"}
              active={true}
              isMobile={isMobile}
            />
          </div>

          <Button className="animate_button_scale">Suscríbete gratis</Button>
        </div>
      </div>
      <div className="cls_container_subTitle">
        <img src={iconMano} alt="subTitle" />
        <h2 className="cls_container_subTitle_text">
          EL BIENESTAR CORPORATIVO{" "}
          <span>ES LA CLAVE DEL ÉXITO EMPRESARIAL</span>
        </h2>
      </div>
      <div className="cls_container_que_nos_hace_diferent">
        <div className="cls_container_que_nos_hace_diferent_img">
          <img src={dr} alt="doctor" />
        </div>
        <div className="cls_container_collapse">
          <h3>¿QUÉ NOS HACE DIFERENTES Y ÚNICOS?</h3>
          <div>
            <Collapse
              style={{ backgroundColor: "transparent", border: "none" }}
              activeKey={openPanels}
              onChange={(e) => {
                setOpenPanels(e[e.length - 1]);
              }}
            >
              <CollapsePanel
                className={
                  openPanels === "1"
                    ? "cls_title_collapse active"
                    : "cls_title_collapse"
                }
                header="Plataforma Inteligente"
                key="1"
              >
                <p>
                  Lo que nos diferencia es nuestra avanzada tecnología de
                  Inteligencia Artificial, integrada en nuestra App. Esta IA nos
                  permite mejorar, optimizar y predecir en un 40% la
                  funcionalidad de nuestra plataforma, garantizando una
                  experiencia única y efectiva para todos los usuarios.
                </p>
              </CollapsePanel>
              <CollapsePanel
                className={
                  openPanels === "2"
                    ? "cls_title_collapse active"
                    : "cls_title_collapse"
                }
                header="Enfoque integral de bienestar corporativo"
                key="2"
              >
                <p>
                  Su enfoque integral de bienestar corporativo no solo se centra
                  en la atención médica, sino que también promueve un estilo de
                  vida saludable y el bienestar general en el entorno laboral.
                </p>
              </CollapsePanel>
              <CollapsePanel
                className={
                  openPanels === "3"
                    ? "cls_title_collapse active"
                    : "cls_title_collapse"
                }
                header="Amplía la protección de tu familia directa"
                key="3"
              >
                <p>
                  Refuerza su enfoque en la salud y el bienestar integral,
                  reconociendo que el cuidado de la salud de la familia es una
                  parte esencial del bienestar del empleado.
                </p>
              </CollapsePanel>
              <CollapsePanel
                className={
                  openPanels === "4"
                    ? "cls_title_collapse active"
                    : "cls_title_collapse"
                }
                header="Estadísticas y análisis"
                key="4"
              >
                <p>
                  Esta información puede ser invaluable para las empresas que
                  buscan mejorar el bienestar de sus empleados, permitiendo a
                  los usuarios y a las empresas obtener una visión clara y
                  precisa de su estado de salud y bienestar.
                </p>
              </CollapsePanel>
            </Collapse>
          </div>
        </div>
      </div>
      <div className="cls_container_beneficios">
        <h2 className="cls_container_beneficios_title">
          BENEFICIOS <span>PARA TU ORGANIZACIÓN</span>
        </h2>
        <div className="cls_container_icons">
          <div className="cls_container_icon">
            <img src={iconMaleta} alt="maleta" />
            <h4>Mayor productividad</h4>
          </div>
          <div className="cls_container_icon">
            <img src={iconSilla} alt="escritorio" />
            <h4>Reducción del ausentismo</h4>
          </div>
          <div className="cls_container_icon">
            <img src={iconManos} alt="manos" />
            <h4>Mejor ambiente laboral</h4>
          </div>
          <div className="cls_container_icon">
            <img src={iconEscalera} alt="superacion" />
            <h4>Retención de talento</h4>
          </div>
        </div>
      </div>
      <Suscribete />
      <Footer />
      <CustomModal
        title={"Medicina Familiar"}
        content={
          "Ofrecemos consultas de medicina familiar hasta dos veces al mes. Nuestros médicos de familia están capacitados para diagnosticar y tratar una amplia variedad de enfermedades y trastornos, y para coordinar la atención con otros especialistas cuando es necesario. También se enfocan en la prevención de enfermedades y la promoción de estilos de vida saludables."
        }
        resaltContent={
          "Si el afiliado no puede asistir, sus familiares directos pueden utilizar este servicio."
        }
        open={isModalOpen}
        onClose={handleCancel}
        image={isMobile ? imgCard1Short : imgCard1}
      />
      <CustomModal
        title={"Psicología"}
        content={
          "Brindamos una consulta mensual de psicología. Nuestros psicólogos están preparados para ayudar a los afiliados a manejar y superar problemas emocionales y de comportamiento."
        }
        resaltContent={
          "Si el afiliado no puede asistir, sus familiares directos pueden utilizar este servicio."
        }
        open={isModalOpen2}
        onClose={handleCancel2}
        image={isMobile ? imgCard2Short : imgCard2}
      />
      <CustomModal
        title={"Nutrición"}
        content={
          "Ofrecemos una consulta mensual de nutrición. Nuestros nutricionistas pueden proporcionar información sobre cómo una dieta saludable puede prevenir o tratar enfermedades, y cómo ciertos alimentos pueden afectar la salud."
        }
        resaltContent={
          "Si el afiliado no puede asistir, sus familiares directos pueden utilizar este servicio."
        }
        open={isModalOpen3}
        onClose={handleCancel3}
        image={isMobile ? imgCard3Short : imgCard3}
      />

      <CustomModal
        title={"Finanzas Personales"}
        content={
          "Brindamos una consulta mensual de finanzas personales. Nuestros asesores financieros pueden ayudar a los afiliados a manejar su dinero, a planificar para el futuro, a comprar seguros, a comprar una casa, a planificar la jubilación y a gestionar la deuda."
        }
        resaltContent={
          "Si el afiliado no puede asistir, sus familiares directos pueden utilizar este servicio."
        }
        open={isModalOpen4}
        onClose={handleCancel4}
        image={isMobile ? imgCard4Short : imgCard4}
      />
    </div>
  );
}

import React, { useState } from "react";
import { Button, Divider, Drawer } from "antd";
import {
  HomeOutlined,
  UsergroupAddOutlined,
  UserAddOutlined,
  FileDoneOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import "./style.scss";
import { useNavigate } from "react-router-dom";

export default function Menu() {
  const [open, setOpen] = useState(false);
  const routerNavigate = useNavigate();

  return (
    <div id="id_menu">
      <div
        className={`menu ${open ? "open" : ""}`}
        onClick={() => setOpen(true)}
      >
        <span className="line line1"></span>
        <span className="line line2"></span>
        <span className="line line3"></span>
      </div>
      {/* <Button onClick={() => setOpen(true)}>open</Button> */}
      <Drawer
        title=" "
        placement="right"
        onClose={() => setOpen(false)}
        open={open}
      >
        <div className="cls_container_menu">
          <Button onClick={() => routerNavigate("/")} icon={<HomeOutlined />}>
            Inicio
          </Button>
          <Divider />
          <Button
            onClick={() => routerNavigate("/nosotros")}
            icon={<UsergroupAddOutlined />}
          >
            Nosotros
          </Button>
          <Divider />
          <Button
            onClick={() => routerNavigate("/contacto")}
            icon={<UserAddOutlined />}
          >
            Contacto
          </Button>
          <Divider />
          <Button
            onClick={() => routerNavigate("/blog")}
            icon={<FileDoneOutlined />}
          >
            Blog
          </Button>
          <Divider />
          <Button
            onClick={() => routerNavigate("/preguntas")}
            icon={<QuestionCircleOutlined />}
          >
            Preguntas Frecuentes
          </Button>
          <Divider />
          <div id="id_menu_interior">
          <div className="cls_option_menu">
            {/* <h4>portal del Cliente</h4> */}
            <Button >Suscribete Aqui</Button>

          </div>
            </div>         
        </div>
      </Drawer>
    </div>
  );
}

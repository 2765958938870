import "./style.scss";

export default function CardService({ img, title, active, eventClick, isMobile }) {
  return (
    <div
      onClick={eventClick}
      id="id_card_service"
      style={{ border: active ? "2px solid #b01319 " : null,  padding: active&&!isMobile ? "30px 0px" : null}}
    >
      <img src={img} alt="carsService" />
      <div className="cls_container_title">
        <h2>{title}</h2>
        {active && <span>Servicio adicional</span>}
      </div>
    </div>
  );
}

import React, { useEffect} from "react";
import ContainerRed from "../../components/ContainerRed";
import Header from "../../components/header";
import { Button, Col, Row } from "antd";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import CardPrincipal from "../../components/CardBlog/Principal";
import CardBlog from "../../components/CardBlog/body";
import "./style.scss";

import { useNavigate } from "react-router-dom";
import data from "./data";

export default function Blog() {
  const header = <Header />;
  const navigate = useNavigate();
  gsap.registerPlugin(ScrollTrigger);

  useEffect(()=> {
    
    gsap.from("#cls_blog_descubre_mejorar",{
      scrollTrigger:{
        trigger: "#id_container_blog",
        toggleActions: "play reverse restart reverse",
        start:'top center',
      },
      opacity: 0, 
      x: -50,
      duration: 1.7,
    });

  });

  return (
    <div>
      <ContainerRed header={header} title={"Blog Doctor"} />
      <div id="id_container_blog">
        <div className="cls_container_title">
          <h1 id="cls_blog_descubre_mejorar">
            Descubre cómo mejorar tu bienestar con nuestro contenido exclusivo.
          </h1>
          <p>
            Suscríbete para recibir actualizaciones{" "}
            <span className="cls_link">aquí.</span>
          </p>
          <div className="cls_group_btn">
            <Button onClick={() => navigate(`/blog`)}>TODO</Button>
            <Button onClick={() => navigate(`/blog/salud`)}>SALUD </Button>
            <Button onClick={() => navigate(`/blog/finanzas`)}>FINANZAS</Button>
            <Button onClick={() => navigate(`/blog/tecnologia`)}>TECNOLOGÍA</Button>
          </div>
        </div>
        <CardPrincipal />

        <div className="cls_container_body_blog">
          <Row gutter={16}>
            {data.map((element, index) => {
              console.log(`/blog/${element.name_category}/${element.name_url}`);
              return (
                <Col
                  onClick={() =>
                    navigate(
                      `/blog/${element.name_category}/${element.name_url}`
                    )
                  }
                  key={`card${index}`}
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                >
                  <CardBlog data={element} />
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    </div>
  );
}

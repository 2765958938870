import "./style.scss";
import logo from "../../assets/logo-vertical.png";
import appStore from "../../assets/icons/appStore.svg";
import playStore from "../../assets/icons/googleplay.svg";
import face from "../../assets/icons/facebook.svg";
import linkdin from "../../assets/icons/linkdind.svg";
import instagram from "../../assets/icons/instagram.svg";
import youtu from "../../assets/icons/youtube.svg";
import tiktok from "../../assets/icons/tiktok.svg";
import wsp from "../../assets/icons/wsp-color.svg"
import { scroller } from "react-scroll";

export default function Footer() {
  return (
    <div id="id_footer">
      <div className="cls_container_column">
        <div className="cls_container_column01">
          <img  onClick={() => scroller.scrollTo("id_menu", { smooth: true })}  src={logo} alt="logo2" />
        </div>
        <div className="cls_container_column02">
          <h5>Acerca de nosotros</h5>
          {/* <h5>Cómo funciona</h5> */}
          <h5>Preguntas frecuentes</h5>
          <h5>Blog</h5>
        </div>
        <div className="cls_container_column03">
          <h5 style={{ color: "#b01319", fontWeight: 600 }}>Legal</h5>
          <h5>Política de Privacidad</h5>
          <h5>Términos y Condiciones</h5>
          <h5>Tratamiento de datos personales</h5>
        </div>
        <div className="cls_container_column04">
          <div className="cls_contain_redes">
            <h5 id="id_title_contact">Contacto</h5>
            <div className="cls_btn_wsp">
              <img src={wsp} alt="whatsapp" className="cls_icon" />
              <span>999 999 999</span>
            </div>

            <div className="cls_group_redes">
              <img src={linkdin} alt="linkdin" className="cls_icon" />
              <img src={instagram} alt="instagram" className="cls_icon" />
              <img src={face} alt="facebook" className="cls_icon" />
              <img src={youtu} alt="youtube" className="cls_icon" />
              <img src={tiktok} alt="tiktok" className="cls_icon" />
            </div>
          </div>
          <div className="cls_contain_store">
            <h5 style={{ color: "#b01319", fontWeight: 600 }}>
              Suscríbete gratis
            </h5>

            <div className="cls_cotainer_store">
              <img src={playStore} alt="gooGleplay" />
              <img src={appStore} alt="appStore" />
            </div>
          </div>
        </div>
      </div>
      <h5 className="cls_derechos">
        © Doctor App Inc. 2023 - Todos los derechos reservados
      </h5>
    </div>
  );
}

import {  Col, Modal, Row } from "antd";
import React from "react";

import './style.scss';

export default function CustomModal(props) {
  const {title, content, resaltContent, image, onClose, open} = props

  return (
    <Modal 
        className="cls_custom_modal"
        title={<h2 className="cls_modal_title">{title!==undefined ? title :''}</h2>}
        open={open} 
        footer={null} 
        maskClosable={true} // Permite cerrar el modal al hacer clic fuera de él
        closable={true}
        onCancel={onClose}
        width={'70%'}    
    >
        <Row className="cls_custom_modal_body" >
            <Col className="cls_custom_modal_body_image">
                <div style={{height: '100%', borderRadius:'10px'}}>
                    <img src={image} alt="card_image"/> 
                </div>
            </Col>
            <Col className="cls_custom_modal_body_content">
                <p>{content}</p>
                <p><strong >{resaltContent}</strong></p>
            </Col>
        </Row>
    </Modal>
  );
}

import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import data from "../data";
import ContainerRed from "../../../components/ContainerRed";
import Header from "../../../components/header";
import "./style.scss";
import { Col, Divider, Space, List } from "antd";
import Footer from "../../../components/footer";
import { FacebookOutlined, LinkedinOutlined, PlusOutlined } from "@ant-design/icons";
import CardBlog from "../../../components/CardBlog/body";
import { Carousel } from "react-responsive-carousel";

export default function ArticleTecnologia() {
  let { id } = useParams();
  const navigate = useNavigate();

  const header = <Header />;
  const dataArticle = data.find((dataArticle) => dataArticle.name_url === id);
  const isMobile =
    /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(
      navigator.userAgent
    );
    const [datosVisibles, setDatosVisibles] = useState([]);
    const [isActive, setActive] = useState({});
    const toggleDescripcion = (id) => {
      if (datosVisibles.includes(id)) {
        setDatosVisibles(datosVisibles.filter((datoId) => datoId !== id));
        isActive[id]=false;
        setActive(isActive);
      } else {
        setDatosVisibles([...datosVisibles, id]);
        isActive[id]=true;
        setActive(isActive);
      }
    };
  return (
    <div>
      <ContainerRed header={header} />
      <div id="id_container_art_tecnologia">
        <div className="cls_container_title">
          <h1>{dataArticle.title}</h1>
        </div>
        <div className="cls_info_author">
            <h4>Publicado {dataArticle.fecha}{" | "}{dataArticle.author}</h4>
        </div>
        <div className="cls_container_imgP">
          <img src={dataArticle.img_principal} alt="imgBlog" />
        </div>
        <div className="cls_body_article">
          <div className="cls_body_left">
            <List
              header={<div>{dataArticle.description[0]}</div>}
              footer={
                <div>
                  {dataArticle.description[dataArticle.description.length - 1]}
                </div>
              }
              dataSource={dataArticle.description.slice(
                1,
                dataArticle.description.length - 2
              )}
              renderItem={(item) => (
                <List.Item key={item.split('\n')[0]}>
                  <p className="list-item-title"><strong onClick={() => toggleDescripcion(item.split('\n')[0])}><PlusOutlined className="rotate-icon" rotate={isActive[item.split('\n')[0]] ? 45 : 0} />{item.split('\n')[0]}</strong></p>
                  {datosVisibles.includes(item.split('\n')[0]) && <p style={{paddingLeft: "1vw",}}>{item.split('\n')[1]}</p>}
                </List.Item>
              )}
            />
          </div>
          <Divider type="vertical" />
          <div className="cls_body_right">
            COMPARTIR{" "}
            <div className="cls_group_redes">
              <LinkedinOutlined
                className="cls_icon"
                onClick={() => {
                  window.location.href = dataArticle.share_linkeding;
                }}
              />
              <FacebookOutlined
                className="cls_icon"
                onClick={() => {
                  window.location.href = dataArticle.share_facebook;
                }}
              />
            </div>
            PUBLICACIONES RECIENTES
            <div className="cls_recent_posts">
              {isMobile ? (
                <Carousel
                  autoPlay
                  infiniteLoop={true}
                  style={{ paddingBottom: "25px" }}
                >
                  {data.map((element) => {
                    return (
                      <div>
                        <CardBlog data={element} />
                      </div>
                    );
                  })}
                </Carousel>
              ) : (
                <Space direction="vertical">
                  {data.map((element, index) => {
                    console.log(
                      `/blog/${element.name_category}/${element.name_url}`
                    );
                    return (
                      <Col
                        onClick={() =>
                          navigate(
                            `/blog/${element.name_category}/${element.name_url}`
                          )
                        }
                        key={`card${index}`}
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                      >
                        <CardBlog data={element} />
                      </Col>
                    );
                  })}
                </Space>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

import React, { useEffect } from "react";
import Header from "../../components/header";
import ContainerRed from "../../components/ContainerRed";
import "./style.scss";
import FormDr from "../../components/form";
import Footer from "../../components/footer";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

export default function Contact() {
  const header = <Header />;
  gsap.registerPlugin(ScrollTrigger);

  useEffect(()=> {
    
    gsap.from("#cls_contact_us_primer_paso",{
      scrollTrigger:{
        trigger: ".cls_container_section",
        toggleActions: "play reverse restart reverse",
        start:'top center',
      },
      opacity: 0, 
      x: -50,
      duration: 1.7,
    });

    gsap.from("#cls_contact_us_salud_fundamental",{
      scrollTrigger:{
        trigger: ".cls_container_section",
        toggleActions: "play reverse restart reverse",
        start:'top center',
      },
      opacity: 0, 
      y: 50,
      duration: 1.7,
    });
  });

  return (
    <div>
      <ContainerRed header={header} />
      <div id="id_container_contact">
        <div className="cls_container_section">
          <div className="cls_form_left">
            <h2 id="cls_contact_us_primer_paso">
              ¡Da el primer paso hacia una empresa más{" "}
              <span>saludable y productiva!</span>
            </h2>
            <h6>
              Suscribe un mes gratis a tu empresa en Doctor App y descubre los
              beneficios de nuestra plataforma de bienestar corporativo.
            </h6>
            <p>
              Doctor App estará disponible de forma gratuita del 1 al 30 de
              noviembre del 2023. Tendrás acceso completo a nuestras funciones y
              servicios diseñados para mejorar tu salud y bienestar. Desde
              consultas médicas virtuales hasta herramientas de nutrición y
              apoyo psicológico, Doctor App tiene todo lo que necesitas para
              cuidar de tu bienestar y el de tu equipo.
            </p>
          </div>
          <div className="cls_form_right">
            <div className="cls_container_form">
              <FormDr />
            </div>
          </div>
        </div>
        <div className="cls_container_title">
          <h2 id="cls_contact_us_salud_fundamental">
            La salud es fundamental y estamos comprometidos contigo para
            garantizarlo.
          </h2>
        </div>
        <div className="cls_container_video">
          <h2>
            Descubre cómo juntos podemos hacer de la salud un derecho alcanzable
            para todos
          </h2>

          <iframe
            className="cls_style_movie"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/FEbBEAzqqtg"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <Footer />
    </div>
  );
}

import "./App.scss";
import "./index.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { About, Blog, Home, Contact, Question } from "./views";
import PageCate from "./views/Blog/categoria";
import ArticleSalud from "./views/Article/salud";
import ArticleFinanza from "./views/Article/finanzas";
import ArticleTecnologia from "./views/Article/tecnologia";

function App() {
  return (
    <BrowserRouter>
      {/* <Header /> */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/nosotros" element={<About />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/contacto" element={<Contact />} />
        <Route path="/preguntas" element={<Question />} />
        <Route path="/blog/:title" element={<PageCate />} />
        <Route path="/blog/salud/:id" element={<ArticleSalud />} />
        <Route path="/blog/tecnologia/:id" element={<ArticleTecnologia />} />
        <Route path="/blog/finanzas/:id" element={<ArticleFinanza />} />
        {/* rutas no especificadas */}
        {/* <Route path="*" element={<Error />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;

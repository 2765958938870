import { Button, Checkbox, Col, Form, Input, Row, Select } from "antd";
import { useRef, useState } from "react";
import './style.css'

export default function FormDr() {
  const [typeUser, setTypeUser] = useState(2);

  const formRef = useRef(null);
  const onFinish = async (values) => {
    console.log(values);
  };

  const selectionTypeUser = (e) => {
    setTypeUser(e);
  };
  return (
    <Form
      layout="vertical"
      ref={formRef}
      name="form_item_path"
      onFinish={onFinish}
      id="id_form"
    >
      <Select
      defaultValue={2}
        style={{
          width: 150,
        }}
        onChange={selectionTypeUser}
        options={[
          {
            value: 2,
            label: "Empleador",
          },
          {
            value: 1,
            label: "Colaborador",
          },
        ]}
      />
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="nombre"
            label="Nombres"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input autoComplete="off" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label="Apellidos"
            name="apellido"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input autoComplete="off" />
          </Form.Item>
        </Col>
        {typeUser === 1 ? (
          <>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ textAlign: "initial", paddingTop:10 }}
            >
              DATOS DE REFERENCIA:
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="nombreReferido"
                label="Nombres"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input autoComplete="off" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label="Apellidos"
                name="apellidoReferido"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input autoComplete="off" />
              </Form.Item>
            </Col>
          </>
        ) : null}
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="correo"
            label="Correo electrónico"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input autoComplete="off" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label="Teléfono"
            name="telefono"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input autoComplete="off" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item
            name="nombreEmpresa"
            label="Nombre de tu empresa"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input autoComplete="off" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item
            name="ruc"
            label="RUC"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input autoComplete="off" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item
            name="cantColaboradores"
            label="Cantidad de colaboradores de tu empresa"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              options={[
                {
                  value: 1,
                  label: "Empreador",
                },
                {
                  value: 2,
                  label: "Colaborador",
                },
              ]}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="cargo"
            label="Cual es tu cargo"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input autoComplete="off" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label="Cual es tu área"
            name="area"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input autoComplete="off" />
          </Form.Item>
        </Col>

        
      </Row>

      <Form.Item
        name="disabled"
        valuePropName="checked"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Checkbox style={{padding:'15px 0'}}>
          <h5 className="cls_style_check">
          Acepto que se traten mis datos para atender esta solicitud.
          </h5>
        </Checkbox>
      </Form.Item>
      <Form.Item>
        <Button className="cls_btn_submit">Suscribirme gratis</Button>
      </Form.Item>
    </Form>
  );
}

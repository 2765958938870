import "./style.css";
import logo from "../../assets/logo_web-09.svg";
import Menu from "../Menu";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

export default function Header() {

  const navigate = useNavigate();

  return (
    <div id="id_header">
      {/* <div className="cls_header"> */}
      <div className="cls_container_logo">
        <img style={{cursor:'pointer'}} onClick={() => navigate('/')} src={logo} alt="logo" />

      </div>
      <div className="cls_menu_header">
        <div className="cls_option_menu">
          {/* <h4>portal del Cliente</h4> */}
          <Button >Suscribete Aqui</Button>
        </div>
        <Menu />
      </div>
      {/* <Menu /> */}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import ContainerRed from "../../components/ContainerRed";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Suscribete from "../../components/Suscribete";
import ButtonSuscribete from "../../components/Button/ButtonSuscribete";
import { Button } from "antd";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Carousel } from "react-responsive-carousel";
import mano from "../../assets/about/celular.png";
import familia from "../../assets/about/familia.png";
import verCorazon from "../../assets/icons/evc-corazon-v2.svg";
import verDocs from "../../assets/icons/vec-docs-v2.svg";
import verFlecha from "../../assets/icons/vec-flecha-v2.svg";
import vector from "../../assets/icons/vector-v2.svg";
import dr01 from "../../assets/medico-1.png";
import dr02 from "../../assets/medico-2.png";
import dr03 from "../../assets/medico-3.png";
import imgCard1 from "../../assets/about/card_1.png";
import imgCard2 from "../../assets/about/card_2.png";
import imgCard3 from "../../assets/about/card_3.png";
import imgCard4 from "../../assets/about/card_4.png";
import imgCard1Short from "../../assets/medicina-familiar.png";
import imgCard2Short from "../../assets/psicologia.png";
import imgCard3Short from "../../assets/nutricion.png";
import imgCard4Short from "../../assets/finanzas-personales.png";

import "./style.scss";
import CustomModal from "../../components/Modal/CustomModal";

export default function About() {
  const header = <Header />;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [isModalOpen4, setIsModalOpen4] = useState(false);

  const isMobile =
    /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(
      navigator.userAgent
    );

  const showModal = () => {
    setIsModalOpen(true);
  };
  const showModal2 = () => {
    setIsModalOpen2(true);
  };
  const showModal3 = () => {
    setIsModalOpen3(true);
  };
  const showModal4 = () => {
    setIsModalOpen4(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };
  const handleCancel3 = () => {
    setIsModalOpen3(false);
  };
  const handleCancel4 = () => {
    setIsModalOpen4(false);
  };

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    gsap.from("#id_container_section_two_right", {
      scrollTrigger: {
        trigger: "#id_container_section_two",
        toggleActions: "play none restart reverse",
        start: "top center",
      },
      x: 100,
      duration: 2,
      opacity: 0,
    });

    gsap.from("#id_about_rompiendo_barrera", {
      scrollTrigger: {
        trigger: "#id_container_section_three",
        toggleActions: "play none restart reverse",
        start: "top center",
      },
      y: 100,
      duration: 0.5,
      opacity: 0,
    });

    gsap.from("#id_about_como_beneficia", {
      scrollTrigger: {
        trigger: "#id_container_section_four",
        toggleActions: "play none restart reverse",
        start: "top center",
      },
      y: 100,
      duration: 0.5,
      opacity: 0,
    });

    gsap.from("#id_title_section_five", {
      scrollTrigger: {
        trigger: "#id_container_section_five",
        toggleActions: "play none restart reverse",
        start: "top center",
      },
      y: 100,
      duration: 0.5,
      opacity: 0,
    });

    gsap.from("#id_about_como_beneficia_two", {
      scrollTrigger: {
        trigger: "#id_container_section_six",
        toggleActions: "play none restart reverse",
        start: "top center",
      },
      y: 100,
      duration: 0.5,
      opacity: 0,
    });

    gsap.from(".cls_suscribete_title_effect",{
      scrollTrigger:{
        trigger: ".cls_suscribete_title_effect",
        toggleActions: "play none none none",
        start:'center center',
      },
      y: 100,
      duration: .5,
      opacity:0,
    });

    gsap.from(".cls_suscribete_imagen_effect",{
      scrollTrigger:{
        trigger: ".cls_flex_right",
        toggleActions: "play none restart reverse",
        start:'center center',
      },
      x: 100,
      duration: 3,
      opacity:0,
    });
  });

  return (
    <div id="id_page_about">
      <ContainerRed header={header} title={"Acerca de nosotros"} />
      <div id="id_container_section_two">
        <div id="id_container_section_two_left">
          <h2>
            Transformamos la salud de tu{" "}
            <span style={{ color: "rgb(176,19,25)" }}>
              empresa en bienestar
            </span>
          </h2>
          <p>
            {" "}
            La integración de Inteligencia Artificial, mejora y optimiza su
            funcionalidad brindándote servicios de salud integral a tus
            colaboradores y sus familiares directos.
          </p>
          <ButtonSuscribete clase={"animate_button_scale"}></ButtonSuscribete>
        </div>
        <div id="id_container_section_two_right">
          <img src={mano} alt="imagenMano" />
        </div>
      </div>
      <div id="id_container_section_three">
        <div id="id_about_rompiendo_barrera">
          <h2>
            Rompiendo barreras:<br /> la innovación que nos hace únicos
          </h2>
          <h4>Accede a nuestros servicios 100 % gratuitos</h4>
        </div>
        <div className="cls_content">
          <div className="cls_icon">
            <img src={familia} alt="imagenMano" />
          </div>
          <div id="id_content_options">
            <Button icon={<CheckCircleOutlined />} onClick={showModal}>
              Medicina Familiar
            </Button>

            <Button icon={<CheckCircleOutlined />} onClick={showModal2}>
              Psicología
            </Button>

            <Button icon={<CheckCircleOutlined />} onClick={showModal3}>
              Nutrición
            </Button>

            <Button icon={<CheckCircleOutlined />} onClick={showModal4}>
              Finanzas Personales
            </Button>
          </div>
        </div>
      </div>
      <div id="id_container_section_four">
        <h3 id="id_about_como_beneficia">¿Cómo se beneficia tu empresa?</h3>
        <div className="cls_container_body">
          <div className="cls_flex_left">
            <ul>
              <li className="cls_item_list" style={{ display: "flex" }}>
                <div>
                  <strong style={{ fontSize: "30px", paddingRight: "10px" }}>
                    ·
                  </strong>
                </div>
                <div>
                  Optimiza la gestión de los beneficios que ofreces a tu equipo,
                  todo desde un solo lugar.
                </div>
              </li>
              <li className="cls_item_list" style={{ display: "flex" }}>
                <div>
                  <strong style={{ fontSize: "30px", paddingRight: "10px" }}>
                    ·
                  </strong>
                </div>
                <div>Motiva a tu equipo a establecer hábitos saludables.</div>
              </li>
              <li className="cls_item_list" style={{ display: "flex" }}>
                <div>
                  <strong style={{ fontSize: "30px", paddingRight: "10px" }}>
                    ·
                  </strong>
                </div>
                <div>
                  Obtén datos valiosos para mejorar y personalizar la propuesta
                  de beneficios para tus colaboradores.
                </div>
              </li>
              <li className="cls_item_list" style={{ display: "flex" }}>
                <div>
                  <strong style={{ fontSize: "30px", paddingRight: "10px" }}>
                    ·
                  </strong>
                </div>
                <div>
                  Simplifica la comunicación interna hacia tu equipo al
                  centralizarla en una sola plataforma.
                </div>
              </li>
            </ul>
          </div>
          <div className="cls_flex_right">
            <Carousel
              showArrows={false}
              showThumbs={false}
              autoPlay
              infiniteLoop={true}
            >
              <div className="cls_card_slider">
                <img
                  src={verCorazon}
                  className="cls_card_img"
                  alt="vectCorazon"
                />
                <h5 className="cls_letters_slider">Optimiza beneficios</h5>
              </div>
              <div className="cls_card_slider">
                <img src={vector} className="cls_card_img" alt="vector" />
                <h5 className="cls_letters_slider">Analiza hábitos</h5>
              </div>
              <div className="cls_card_slider">
                <img src={verDocs} className="cls_card_img" alt="vectDocs" />
                <h5 className="cls_letters_slider">
                  Visión general de tu equipo
                </h5>
              </div>
              <div className="cls_card_slider">
                <img
                  src={verFlecha}
                  className="cls_card_img"
                  alt="vectFlecha"
                />
                <h5 className="cls_letters_slider">
                  Mejora la comunicación interna
                </h5>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
      <div id="id_container_section_five">
        <h2 id="id_title_section_five">
          DOCTOR APP: Tu camino hacia el bienestar. <br />
          <span>
            Accede a servicios integrales de salud y bienestar de calidad.
          </span>
        </h2>
        <Button className="animate_button_scale">Suscríbete gratis</Button>
      </div>
      <div id="id_container_section_six">
        <h3 id="id_about_como_beneficia_two">¿Cómo se beneficia tu empresa?</h3>
        <div className="cls_container_body">
          <div className="cls_flex_left">
            <Carousel
              showArrows={false}
              showThumbs={false}
              autoPlay
              infiniteLoop={true}
            >
              <div className="cls_card_slider">
                <img
                  src={dr01}
                  className="cls_card_img_med"
                  alt="vectCorazon"
                />
                <h5 className="cls_letters_slider">Dra. Alessandra Mendoza</h5>
              </div>
              <div className="cls_card_slider">
                <img src={dr02} className="cls_card_img_med" alt="vector" />
                <h5 className="cls_letters_slider">Dra. enrrique rojas</h5>
              </div>
              <div className="cls_card_slider">
                <img src={dr03} className="cls_card_img_med" alt="vectDocs" />
                <h5 className="cls_letters_slider">Dr. Alejadro</h5>
              </div>
            </Carousel>
          </div>
          <div className="cls_flex_right">
            <ul>
              <li className="cls_item_list" style={{ display: "flex" }}>
                <div>
                  <strong style={{ fontSize: "30px", paddingRight: "10px" }}>
                    ·
                  </strong>
                </div>
                <div>
                  Staff de profesionales licenciados con capacitación de
                  instituciones de primer nivel.
                </div>
              </li>
              <li className="cls_item_list" style={{ display: "flex" }}>
                <div>
                  <strong style={{ fontSize: "30px", paddingRight: "10px" }}>
                    ·
                  </strong>
                </div>
                <div>
                  Disponibilidad las 24/7 para brindar atención médica
                  personalizada y de alta calidad.
                </div>
              </li>
              <li className="cls_item_list" style={{ display: "flex" }}>
                <div>
                  <strong style={{ fontSize: "30px", paddingRight: "10px" }}>
                    ·
                  </strong>
                </div>
                <div>
                  liderazgo multidisciplinario enfocado en mejorar la atención
                  integral para todos.
                </div>
              </li>
              <li className="cls_item_list" style={{ display: "flex" }}>
                <div>
                  <strong style={{ fontSize: "30px", paddingRight: "10px" }}>
                    ·
                  </strong>
                </div>
                <div>
                  Compromiso con la excelencia y la mejora continua de nuestros
                  servicios.
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Suscribete />
      <Footer />

      <CustomModal
        title={"Medicina Familiar"}
        content={
          "Ofrecemos consultas de medicina familiar hasta dos veces al mes. Nuestros médicos de familia están capacitados para diagnosticar y tratar una amplia variedad de enfermedades y trastornos, y para coordinar la atención con otros especialistas cuando es necesario. También se enfocan en la prevención de enfermedades y la promoción de estilos de vida saludables."
        }
        resaltContent={
          "Si el afiliado no puede asistir, sus familiares directos pueden utilizar este servicio."
        }
        open={isModalOpen}
        onClose={handleCancel}
        image={isMobile ? imgCard1Short : imgCard1}
      />
      <CustomModal
        title={"Psicología"}
        content={
          "Brindamos una consulta mensual de psicología. Nuestros psicólogos están preparados para ayudar a los afiliados a manejar y superar problemas emocionales y de comportamiento."
        }
        resaltContent={
          "Si el afiliado no puede asistir, sus familiares directos pueden utilizar este servicio."
        }
        open={isModalOpen2}
        onClose={handleCancel2}
        image={isMobile ? imgCard2Short : imgCard2}
      />
      <CustomModal
        title={"Nutrición"}
        content={
          "Ofrecemos una consulta mensual de nutrición. Nuestros nutricionistas pueden proporcionar información sobre cómo una dieta saludable puede prevenir o tratar enfermedades, y cómo ciertos alimentos pueden afectar la salud."
        }
        resaltContent={
          "Si el afiliado no puede asistir, sus familiares directos pueden utilizar este servicio."
        }
        open={isModalOpen3}
        onClose={handleCancel3}
        image={isMobile ? imgCard3Short : imgCard3}
      />

      <CustomModal
        title={"Finanzas Personales"}
        content={
          "Brindamos una consulta mensual de finanzas personales. Nuestros asesores financieros pueden ayudar a los afiliados a manejar su dinero, a planificar para el futuro, a comprar seguros, a comprar una casa, a planificar la jubilación y a gestionar la deuda."
        }
        resaltContent={
          "Si el afiliado no puede asistir, sus familiares directos pueden utilizar este servicio."
        }
        open={isModalOpen4}
        onClose={handleCancel4}
        image={isMobile ? imgCard4Short : imgCard4}
      />
    </div>
  );
}

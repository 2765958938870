const data = [
  {
    id: 1,
    title: "Claves para mantener un estilo de vida saludable",
    description: [
      "Mantener un estilo de vida saludable implica adoptar hábitos y tomar decisiones que promuevan el bienestar físico y mental a largo plazo. Aquí tienes algunas claves importantes para lograrlo:",
      "Alimentación equilibrada: Prioriza una dieta rica en frutas, verduras, granos integrales, proteínas magras y grasas saludables. Evita los alimentos procesados, altos en grasas saturadas, azúcares y sodio.",
      "Hidratación adecuada: Beber suficiente agua es esencial para el buen funcionamiento del cuerpo. Mantén una hidratación adecuada evitando bebidas azucaradas y alcohólicas.",
      "Actividad física regular: Realiza ejercicio de forma regular, al menos 150 minutos de actividad aeróbica moderada o 75 minutos de actividad intensa por semana. Combina ejercicios cardiovasculares con entrenamiento de fuerza y flexibilidad.",
      "Descanso y sueño de calidad: Duerme entre 7 y 8 horas diarias para permitir que tu cuerpo se recupere adecuadamente. Establece una rutina de sueño regular y crea un ambiente propicio para descansar.",
      "Control del estrés: Encuentra formas saludables de manejar el estrés, como la práctica de técnicas de relajación, meditación, yoga, ejercicios de respiración y actividades placenteras. Busca equilibrio entre el trabajo y el tiempo de descanso.",
      "Evitar el consumo de sustancias nocivas: Evita fumar tabaco y limita el consumo de alcohol. Ambos hábitos pueden tener un impacto negativo en tu salud física y mental.",
      "Mantener un peso saludable: Mantén un peso adecuado para tu altura y constitución física. Si es necesario, busca asesoramiento profesional para establecer un plan de pérdida o mantenimiento de peso",
      "Revisiones médicas regulares: Realiza chequeos médicos periódicos y acude a tus citas médicas recomendadas. Esto te ayudará a identificar y tratar problemas de salud de manera temprana.",
      "Relaciones sociales y apoyo emocional: Cultiva relaciones saludables con familiares y amigos. El apoyo social puede tener un impacto positivo en tu bienestar emocional.",
      "Cuidado de la salud mental: Presta atención a tu salud mental y busca ayuda profesional si experimentas síntomas de estrés, ansiedad, depresión u otras enfermedades mentales. No dudes en hablar con un terapeuta o psicólogo.",
      "Recuerda que mantener un estilo de vida saludable es un proceso gradual y requiere compromiso y constancia. Haz cambios pequeños pero sostenibles en tu rutina diaria para mejorar tu bienestar general.",
    ],
    category: 1,
    name_category: "salud",
    name_url: "claves-para-mantener-un-estilo-de-vida-saludable",
    author: "Dr App",
    fecha: "16 de Mayo del 2023",
    time: "4 min escrito",
    img_principal:
      "https://www.paho.org/sites/default/files/primary-care-illustration.jpg",
      img_miniatura: "https://resource.doctorapp.pe/blogminiatur.png",
  },
  {
    id: 2,
    title:
      "Inteligencia artificial y finanzas de la salud: una pareja poderosa",
    description: [
      "La inteligencia artificial (IA) y las finanzas de la salud son, sin duda, una pareja poderosa en la promoción de una vida saludable. La combinación de estas dos áreas puede proporcionar una serie de beneficios significativos en el campo de la atención médica y el bienestar.",
      "En primer lugar, la inteligencia artificial puede ayudar a mejorar el diagnóstico y el tratamiento médico. Con algoritmos avanzados y capacidades de aprendizaje automático, la IA puede analizar grandes cantidades de datos clínicos, históricos y genómicos para identificar patrones y tendencias que los médicos pueden pasar por alto. Esto puede conducir a diagnósticos más precisos y tratamientos más efectivos, lo que a su vez mejora la calidad de vida de los pacientes.",
      "Además, la inteligencia artificial puede desempeñar un papel importante en la gestión financiera de los sistemas de atención médica. Puede analizar datos financieros, evaluar riesgos y optimizar la asignación de recursos para garantizar una utilización eficiente de los recursos disponibles. Esto ayuda a reducir costos, aumentar la rentabilidad y mejorar la sostenibilidad financiera de los proveedores de atención médica.",
      "La IA también puede desempeñar un papel clave en la promoción de la salud y la prevención de enfermedades. Puede analizar datos de salud en tiempo real, como monitoreo de signos vitales, datos de actividad física y hábitos alimentarios, para proporcionar recomendaciones personalizadas de estilo de vida saludable. Esto ayuda a las personas a tomar decisiones informadas sobre su salud y adoptar comportamientos saludables a largo plazo.",
      "En el ámbito de la investigación médica, la inteligencia artificial puede acelerar el descubrimiento de nuevos medicamentos y terapias. Al analizar grandes conjuntos de datos, la IA puede identificar posibles objetivos terapéuticos, diseñar moléculas y predecir su eficacia. Esto tiene el potencial de acelerar significativamente el proceso de desarrollo de medicamentos y reducir los costos asociados.",
      "Sin embargo, es importante tener en cuenta que la implementación de la inteligencia artificial en las finanzas de la salud no está exenta de desafíos. La privacidad y la seguridad de los datos son preocupaciones importantes que deben abordarse para garantizar la confidencialidad y protección de la información médica sensible. Además, se requiere una colaboración estrecha entre profesionales de la salud, expertos en IA y reguladores para garantizar que la tecnología se utilice de manera ética y responsable.",
      "En resumen, la inteligencia artificial y las finanzas de la salud tienen el potencial de transformar la atención médica y promover una vida saludable. Su combinación puede mejorar el diagnóstico y el tratamiento médico, optimizar la gestión financiera de los sistemas de atención médica, fomentar la prevención de enfermedades y acelerar la investigación médica. Sin duda, son una pareja poderosa cuando se trata de mejorar la salud y el bienestar de las personas.",
    ],
    category: 2,
    name_category: "finanzas",
    name_url:
      "inteligencia-artificial-y-finanzas-de-la-salud-una-pareja-poderosa",
    author: "Dr App",
    fecha: "16 de Mayo del 2023",
    time: "4 min escrito",
    img_principal:
      "https://www.paho.org/sites/default/files/primary-care-illustration.jpg",
      img_miniatura: "https://resource.doctorapp.pe/blogminiatur.png",
  },
  {
    id: 3,
    title: "Descubre las innovaciones que están transformando el sector médico",
    description: [
      "El sector médico y la salud están experimentando constantemente innovaciones que están transformando la forma en que se diagnostican, tratan y gestionan las enfermedades. Estas innovaciones están mejorando la calidad de vida de las personas y permitiendo una atención médica más efectiva y personalizada. A continuación, mencionaré algunas de las innovaciones más destacadas:",
      "Inteligencia Artificial (IA) y Aprendizaje Automático: La IA y el aprendizaje automático están siendo aplicados en el campo de la medicina de múltiples maneras. Los algoritmos de IA pueden analizar grandes cantidades de datos clínicos y de investigación para ayudar en el diagnóstico de enfermedades, predecir resultados y desarrollar tratamientos personalizados. También se utilizan para agilizar la interpretación de imágenes médicas, como radiografías y resonancias magnéticas.",
      "Telemedicina: La telemedicina está revolucionando la forma en que se brinda atención médica, especialmente en áreas remotas o con acceso limitado a servicios médicos. Permite a los pacientes realizar consultas médicas a distancia a través de videoconferencias, lo que reduce los costos y las barreras geográficas. Además, la telemedicina facilita el monitoreo remoto de pacientes crónicos y el seguimiento de su progreso.",
      "Tecnología de vestibles (wearables): Los dispositivos de vestir, como los relojes inteligentes y las pulseras de actividad, están equipados con sensores que pueden monitorear constantemente la actividad física, el ritmo cardíaco, el sueño y otros parámetros de salud. Estos datos pueden ayudar a las personas a mantener un estilo de vida saludable y a prevenir enfermedades al brindarles información sobre su estado de salud en tiempo real.",
      "Medicina de precisión: La medicina de precisión utiliza información genética y molecular para personalizar el diagnóstico y el tratamiento de enfermedades. Gracias a los avances en la secuenciación del ADN y la tecnología de análisis genómico, los médicos pueden identificar características específicas de los pacientes y seleccionar terapias que sean más efectivas y con menos efectos secundarios.",
      "Realidad virtual y aumentada: Estas tecnologías se están utilizando en el ámbito de la salud para mejorar la formación de profesionales médicos, ofrecer terapias de rehabilitación y reducir el dolor y la ansiedad de los pacientes durante los procedimientos médicos. La realidad virtual permite recrear entornos virtuales inmersivos, mientras que la realidad aumentada superpone información digital en el mundo real.",
      "Estas son solo algunas de las muchas innovaciones que están transformando el sector médico y la salud en general. A medida que avanza la tecnología, es probable que surjan nuevas y emocionantes formas de mejorar la atención médica y promover una vida saludable.",
    ],
    category: 2,
    name_category: "tecnologia",
    name_url:
      "descubre-las-innovaciones-que-están-transformando-el-sector-médico",
    author: "Dr App",
    fecha: "16 de Mayo del 2023",
    time: "4 min escrito",
    img_principal:
      "https://www.paho.org/sites/default/files/primary-care-illustration.jpg",
      img_miniatura: "https://resource.doctorapp.pe/blogminiatur.png",
  },
];

export default data;

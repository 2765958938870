import { Button } from "antd";
import "./style.scss";
import mano from '../../assets/MANO-V2.png'
import playStore from '../../assets/icons/googleplay.svg'
import appStore from '../../assets/icons/appStore.svg'

function Suscribete() {
  return (
    <div id="id_suscribete">
      <div className="cls_container_body">
        <h2 className="cls_suscribete_title_effect">
          SUSCRÍBE GRATIS <span>A TU EMPRESA</span>
        </h2>
        <div className="cls_cotainer_store">
            <img src={playStore} alt="gooGleplay" />
            <img src={appStore} alt="appStore" />
        </div>
        <Button className="animate_button_scale">Click aquí</Button>
      </div>
      <div className="cls_imagen cls_suscribete_imagen_effect">
        <img src={mano} alt="imagenMano" />
      </div>
    </div>
  );
}

export default Suscribete;

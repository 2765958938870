import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../../../components/footer";
import ContainerRed from "../../../components/ContainerRed";
import Header from "../../../components/header";
import "./style.scss";
import { Col, Row } from "antd";
import CardBlog from "../../../components/CardBlog/body";
import data from "../data";

export default function PageCate() {
  let { title } = useParams();
  const navigate = useNavigate();

  const header = <Header />;
  let dataCate = [];
  dataCate.push(
    data.find((dataArticle) => dataArticle.name_category === title)
  );
  //   const dataArticle[0] =

  // console.log(dataArticle,'asdasdasd');
  return (
    <div>
      <ContainerRed header={header} />
      <div id="id_container_page_cate">
        <div className="cls_container_title">
          <h1>{title.charAt(0).toUpperCase() + title.slice(1)}</h1>
        </div>
        <Row gutter={16}>
          {dataCate.map((element, index) => {
            console.log(`/blog/${element.name_category}/${element.name_url}`);
            return (
              <Col
                onClick={() =>
                  navigate(`/blog/${element.name_category}/${element.name_url}`)
                }
                key={`card${index}`}
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
              >
                <CardBlog data={element} />
              </Col>
            );
          })}
        </Row>
      </div>
      <Footer />
    </div>
  );
}

import React, { useState } from "react";
import ContainerRed from "../../components/ContainerRed";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Suscribete from "../../components/Suscribete";
import "./style.scss";
import { Button, List } from "antd";
import { WhatsAppOutlined } from "@ant-design/icons";
import data from "./data";

export default function Question() {
  const header = <Header />;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isMobile =
    /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(
      navigator.userAgent
    );
    const [datosVisibles, setDatosVisibles] = useState([]);
    const [isActive, setActive] = useState({});
    const toggleDescripcion = (id) => {
      if (datosVisibles.includes(id)) {
        setDatosVisibles(datosVisibles.filter((datoId) => datoId !== id));
        isActive[id]=false;
        setActive(isActive);
      } else {
        setDatosVisibles([...datosVisibles, id]);
        isActive[id]=true;
        setActive(isActive);
      }
    };
  return (
    <div id="id_container_question">
      <ContainerRed header={header} title={"Preguntas Frecuentes"} />
      <div className="cls_question_body">
            <List
              dataSource={data}
              renderItem={(item) => (
                <List.Item key={item.split('\n')[0]}>
                  <p className="cls_question_list"><strong onClick={() => toggleDescripcion(item.split('\n')[0])}>{item.split('\n')[0]}</strong></p>
                  {datosVisibles.includes(item.split('\n')[0]) && <p style={{paddingLeft: "1vw",}}>{item.split('\n')[1]}</p>}
                </List.Item>
              )}
            />
            <center className="cls_question_center">
            <h2 className="cls_question_text_question">¿Tienes alguna consulta?</h2>
            <Button className="cls_question_button">
              <WhatsAppOutlined/>999 999 999
            </Button>
            </center>
      </div>
      <Suscribete />
      <Footer />
    </div>
  );
}
